import React from 'react';
import { useRecentlyViewedStore } from '../lib/store/recentlyViewed';
import ProductCard from './ProductCard';

interface RecentlyViewedProps {
  currentProductId?: string;
  maxItems?: number;
}

const RecentlyViewed: React.FC<RecentlyViewedProps> = ({ 
  currentProductId,
  maxItems = 6 
}) => {
  const products = useRecentlyViewedStore(state => state.products);

  // Filter out current product and get specified number of items
  const recentProducts = products
    .filter(product => product.id !== currentProductId)
    .slice(0, maxItems);

  if (recentProducts.length === 0) return null;

  return (
    <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8 pt-4 pb-16">
      <h2 className="text-3xl font-sans mb-12 text-center">Recently Viewed</h2>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
        {recentProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default RecentlyViewed;