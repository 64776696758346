import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { Product } from '../lib/types/product';
import OptimizedImage from './ui/OptimizedImage';

interface ProductCardProps {
  product: Product;
  priority?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, priority = false }) => {
  const navigate = useNavigate();

  const handleProductClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'instant' });
    navigate(`/product/${product.id}`);
  };

  const productDescription = product.description?.slice(0, 100) || product.title;

  return (
    <div className="group">
      <Link 
        to={`/product/${product.id}`}
        onClick={handleProductClick}
        className="block w-full bg-white rounded-lg overflow-hidden relative"
        aria-label={`View details for ${product.title}`}
      >
        {/* 4:3 portrait aspect ratio container */}
        <div className="relative pb-[133.33%]">
          <OptimizedImage
            src={product.photos[0]}
            alt={`${product.title} - ${productDescription}`}
            sizes="(min-width: 1280px) 33vw, (min-width: 768px) 50vw, 100vw"
            className="absolute inset-0 w-full h-full object-contain transition-transform duration-300 group-hover:scale-105"
            priority={priority}
          />
        </div>
        
        {product.status === 'sold' && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <span className="text-white font-medium text-xl transform -rotate-45">SOLD</span>
          </div>
        )}
      </Link>
      
      <div className="mt-3 space-y-1 px-1">
        <Link 
          to={`/product/${product.id}`}
          onClick={handleProductClick}
          className="block"
        >
          <h3 className="text-sm sm:text-base font-medium group-hover:underline line-clamp-2">
            {product.title}
          </h3>
        </Link>
        <p className="text-sm text-gray-500">{product.category}</p>
        <p className="text-sm sm:text-base font-medium">
          {product.status === 'sold' ? 'SOLD' : `$${product.price.toLocaleString()}`}
        </p>
      </div>
    </div>
  );
};

export default ProductCard;