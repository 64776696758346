import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Home, Search } from 'lucide-react';
import SearchDialog from './SearchDialog';
import { useMediaQuery } from '../../lib/utils/hooks/useMediaQuery';

const MobileNav = () => {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleHomeClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'instant' });
  };

  if (!isMobile) return null;

  return (
    <>
      <div className="fixed bottom-4 left-4 right-4 bg-white border border-gray-200 rounded-full shadow-lg pb-safe-bottom z-50 md:hidden">
        <div className="flex justify-around items-center h-14">
          <button
            onClick={handleHomeClick}
            className="flex flex-col items-center justify-center w-1/2 h-full"
          >
            <Home className="h-5 w-5" />
            <span className="text-xs mt-0.5">Home</span>
          </button>

          <button
            onClick={() => setIsSearchOpen(true)}
            className="flex flex-col items-center justify-center w-1/2 h-full"
          >
            <Search className="h-5 w-5" />
            <span className="text-xs mt-0.5">Search</span>
          </button>
        </div>
      </div>

      <SearchDialog isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
    </>
  );
};

export default MobileNav;