import React from 'react';
import { Link } from 'react-router-dom';
import { ShoppingBag } from 'lucide-react';
import { useCartStore } from '../../lib/store/cart';
import SearchBar from '../SearchBar';
import { useMediaQuery } from '../../lib/utils/hooks/useMediaQuery';

interface NavbarProps {
  onCartClick: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onCartClick }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const cartItems = useCartStore(state => state.items);
  const itemCount = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <nav className="w-full bg-white border-b border-gray-200 pt-safe-top">
      <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <SearchBar />
          </div>
          
          <div className="flex-1 flex justify-center">
            <Link to="/" className="flex items-center justify-center">
              <img 
                src="https://res.cloudinary.com/graphichut/image/upload/v1736986536/showpony1_vpo6o3.jpg"
                alt="SHOW PONY PALM BEACH"
                className="h-12 w-auto object-contain"
              />
            </Link>
          </div>

          <div className="flex items-center">
            <button 
              onClick={onCartClick} 
              className="p-2 hover:bg-gray-50 rounded-md min-h-[44px] min-w-[44px] flex items-center justify-center relative"
              aria-label="Cart"
            >
              <ShoppingBag className="h-6 w-6 text-gray-900" />
              {itemCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-black text-white text-xs font-medium rounded-full w-5 h-5 flex items-center justify-center">
                  {itemCount}
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;