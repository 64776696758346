import React from 'react';
import { X } from 'lucide-react';

interface CartHeaderProps {
  onClose: () => void;
}

const CartHeader: React.FC<CartHeaderProps> = ({ onClose }) => (
  <div className="px-4 py-6 sm:px-6 border-b border-gray-200">
    <div className="flex items-start justify-between">
      <h2 className="text-lg font-medium text-gray-900">Shopping Cart</h2>
      <button
        onClick={onClose}
        className="ml-3 h-7 w-7 flex items-center justify-center hover:bg-gray-100 rounded-full transition-colors"
        aria-label="Close cart"
      >
        <X className="h-6 w-6" />
      </button>
    </div>
  </div>
);

export default CartHeader;