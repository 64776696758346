import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Cart from '../cart/Cart';
import MobileNav from './MobileNav';
import TabletNav from './TabletNav';
import { useScrollBehavior } from '../../lib/hooks/useScrollBehavior';
import { useMediaQuery } from '../../lib/utils/hooks/useMediaQuery';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const [isCartOpen, setIsCartOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  useScrollBehavior();

  return (
    <div className="min-h-screen flex flex-col">
      <div className="fixed w-full top-0 z-50">
        <Navbar onCartClick={() => setIsCartOpen(true)} />
      </div>
      
      <Cart isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
      
      <main className="flex-grow pt-16">
        {children}
      </main>
      
      <Footer />
      {isMobile ? <MobileNav /> : <TabletNav />}
    </div>
  );
};

export default Layout;