import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail } from 'lucide-react';
import { useCartStore } from '../../lib/store/cart';

interface CartFooterProps {
  onClose: () => void;
}

const CartFooter: React.FC<CartFooterProps> = ({ onClose }) => {
  const { items, total } = useCartStore();
  const navigate = useNavigate();

  const handleCheckout = () => {
    // Store cart items in session storage
    sessionStorage.setItem('checkoutItems', JSON.stringify(items));
    
    // Navigate to manual checkout
    navigate('/checkout/manual');
    onClose();
  };

  const handleContactClick = () => {
    const subject = encodeURIComponent('Cart Items Purchase Inquiry');
    const body = encodeURIComponent(
      `Hi, I'm interested in purchasing the following items:\n\n${items
        .map((item) => `${item.title} (Quantity: ${item.quantity})`)
        .join('\n')}\n\nTotal: $${total.toFixed(2)}`
    );
    window.location.href = `mailto:showponysales@gmail.com?subject=${subject}&body=${body}`;
  };

  return (
    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
      <div className="flex justify-between text-base font-medium text-gray-900">
        <p>Total</p>
        <p>${total.toFixed(2)}</p>
      </div>
      <p className="mt-0.5 text-sm text-gray-500">
        Shipping will be calculated at checkout
      </p>
      <div className="mt-6 space-y-4">
        <button
          onClick={handleCheckout}
          className="w-full flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-black hover:bg-gray-800"
        >
          Checkout
        </button>

        <button
          onClick={handleContactClick}
          className="w-full flex items-center justify-center gap-2 px-6 py-3 border-2 border-black text-black rounded-md hover:bg-gray-50 transition-colors"
        >
          <Mail className="h-5 w-5" />
          Contact for Details
        </button>

        <div className="text-center">
          <button
            type="button"
            className="text-sm font-medium text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartFooter;