import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '../lib/utils/hooks/useMediaQuery';
import CategoryNav from './layout/CategoryNav';

const Hero = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleExplore = () => {
    // Add random seed to URL for consistent shuffling
    const randomSeed = Math.random().toString(36).substring(7);
    navigate(`/shop?seed=${randomSeed}`);
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };

  return (
    <div className="relative w-full mb-12">
      {isMobile && <CategoryNav />}
      <div className="relative w-full" style={{ paddingBottom: '50%' }}>
        <img
          src="https://res.cloudinary.com/graphichut/image/upload/v1735525162/showjuan_porhuq.jpg"
          alt="Elegant vintage furniture display"
          className="absolute inset-0 w-full h-full object-cover"
          loading="eager"
          priority="high"
        />
        {!isMobile && <CategoryNav />}
        <div className={`absolute inset-0 flex items-end justify-center ${isMobile ? 'pb-16' : 'pb-32'}`}>
          <button 
            onClick={handleExplore}
            className="bg-white/50 backdrop-blur-sm text-gray-800 px-8 py-3 text-lg rounded-md hover:bg-white/60 transition-colors transform hover:scale-105 duration-200"
          >
            {isMobile ? 'Browse Collection' : 'Explore Collection'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;