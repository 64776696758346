import { supabase } from '../../supabase/client';
import type { Product } from '../../types/product';

// Get a deterministic seed based on the current date
const getDailySeed = () => {
  const today = new Date();
  return today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
};

// Fisher-Yates shuffle with seed
const seededShuffle = (array: any[], seed: string) => {
  const shuffled = [...array];
  const seedNumber = Array.from(seed).reduce((acc, char) => acc + char.charCodeAt(0), 0);
  let currentIndex = array.length;

  while (currentIndex > 0) {
    const randomIndex = Math.floor(
      ((seedNumber * currentIndex) % array.length + currentIndex) % currentIndex
    );
    currentIndex--;
    [shuffled[currentIndex], shuffled[randomIndex]] = [
      shuffled[randomIndex],
      shuffled[currentIndex]
    ];
  }

  return shuffled;
};

export const getFeaturedProducts = async (limit: number = 6): Promise<Product[]> => {
  try {
    // Fetch only published products
    const { data: products, error } = await supabase
      .from('products')
      .select('*')
      .eq('status', 'published');

    if (error) throw error;
    if (!products?.length) return [];

    // Get today's seed and shuffle products
    const seed = getDailySeed();
    const shuffledProducts = seededShuffle(products, seed);

    // Return the first 'limit' products
    return shuffledProducts.slice(0, limit);
  } catch (error) {
    console.error('Error fetching featured products:', error);
    return [];
  }
};