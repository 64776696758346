import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Product } from '../types/product';

interface RecentlyViewedStore {
  products: Product[];
  addProduct: (product: Product) => void;
  clearProducts: () => void;
}

export const useRecentlyViewedStore = create<RecentlyViewedStore>()(
  persist(
    (set) => ({
      products: [],
      addProduct: (product) => {
        set((state) => {
          // Remove product if it already exists
          const filtered = state.products.filter(p => p.id !== product.id);
          // Add product to start of array and limit to 12 items
          return {
            products: [product, ...filtered].slice(0, 12)
          };
        });
      },
      clearProducts: () => set({ products: [] })
    }),
    {
      name: 'recently-viewed-storage'
    }
  )
);