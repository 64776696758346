import React from 'react';
import { ShoppingBag } from 'lucide-react';

const EmptyCart: React.FC = () => (
  <div className="flex-1 flex flex-col items-center justify-center py-12">
    <ShoppingBag className="h-12 w-12 text-gray-400 mb-4" />
    <h3 className="text-lg font-medium text-gray-900 mb-2">
      Your cart is empty
    </h3>
    <p className="text-sm text-gray-500">
      Add some items to start shopping
    </p>
  </div>
);

export default EmptyCart;