import React from 'react';
import { useNavigate } from 'react-router-dom';
import { categories } from '../../lib/categories';
import { formatUrlCategory } from '../../lib/utils/navigation';
import { useMediaQuery } from '../../lib/utils/hooks/useMediaQuery';

const CategoryNav = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleCategoryClick = (categoryName: string) => {
    const urlCategory = formatUrlCategory(categoryName);
    navigate(`/shop/${urlCategory}`);
    window.scrollTo({ top: 0, behavior: 'instant' });
  };

  // Split categories into two rows for mobile
  const midPoint = Math.ceil(categories.length / 2);
  const firstRow = categories.slice(0, midPoint);
  const secondRow = categories.slice(midPoint);

  return (
    <div className={`${isMobile ? 'relative bg-black' : 'absolute top-0 left-0 right-0 z-10 bg-black/30 backdrop-blur-sm'}`}>
      <div className="max-w-[1800px] mx-auto px-4">
        {isMobile ? (
          <div className="flex flex-col py-2">
            <div className="flex justify-center items-center overflow-x-auto no-scrollbar">
              {firstRow.map((category) => (
                <button
                  key={category.name}
                  onClick={() => handleCategoryClick(category.name)}
                  className="px-3 py-2 text-white hover:text-gray-200 transition-colors text-sm whitespace-nowrap"
                >
                  {category.name}
                </button>
              ))}
            </div>
            <div className="flex justify-center items-center overflow-x-auto no-scrollbar">
              {secondRow.map((category) => (
                <button
                  key={category.name}
                  onClick={() => handleCategoryClick(category.name)}
                  className="px-3 py-2 text-white hover:text-gray-200 transition-colors text-sm whitespace-nowrap"
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-16 overflow-x-auto no-scrollbar">
            {categories.map((category) => (
              <button
                key={category.name}
                onClick={() => handleCategoryClick(category.name)}
                className="px-4 py-2 text-white hover:text-gray-200 transition-colors text-sm md:text-base whitespace-nowrap"
              >
                {category.name}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryNav;