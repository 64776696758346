import { toast } from 'sonner';

export const validateCartQuantity = (quantity: number, maxQuantity: number): boolean => {
  if (quantity < 1) {
    toast.error('Quantity must be at least 1');
    return false;
  }
  if (quantity > maxQuantity) {
    toast.error(`Only ${maxQuantity} available`);
    return false;
  }
  return true;
};