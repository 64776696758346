import { categories } from '../../categories';

export const formatUrlCategory = (category: string): string => {
  return category
    .toLowerCase()
    .replace(/\//g, '-')
    .replace(/&/g, 'and')
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
};

export const formatDisplayCategory = (urlCategory: string): string => {
  const category = categories.find(cat => 
    formatUrlCategory(cat.name) === urlCategory
  );
  
  if (category) {
    return category.name;
  }

  return urlCategory
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/And/g, '&');
};