import React from 'react';
import CartItem from './CartItem';
import type { CartItem as CartItemType } from '../../lib/types/cart';

interface CartItemsProps {
  items: CartItemType[];
}

const CartItems: React.FC<CartItemsProps> = ({ items }) => (
  <div className="flow-root">
    <ul className="-my-6 divide-y divide-gray-200">
      {items.map((item) => (
        <CartItem key={item.id} item={item} />
      ))}
    </ul>
  </div>
);

export default CartItems;