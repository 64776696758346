import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { supabase } from '../lib/supabase/client';
import type { Product } from '../lib/types/product';
import OptimizedImage from './ui/OptimizedImage';

const NewArrivals = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data, error } = await supabase
          .from('products')
          .select('*')
          .eq('status', 'published')
          .order('created_at', { ascending: false })
          .limit(12);

        if (error) throw error;
        setProducts(data || []);
      } catch (error) {
        console.error('Error fetching new arrivals:', error);
        setError('Failed to load new arrivals');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = (productId: string) => {
    window.scrollTo({ top: 0, behavior: 'instant' });
    navigate(`/product/${productId}`);
  };

  if (error || (!loading && !products.length)) return null;

  if (loading) {
    return (
      <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className="text-3xl mb-12 text-center">New Arrivals</h2>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          {[...Array(6)].map((_, i) => (
            <div key={i} className="relative pb-[133.33%] bg-gray-100 rounded-lg animate-pulse" />
          ))}
        </div>
      </div>
    );
  }

  const displayedProducts = isExpanded ? products : products.slice(0, 6);

  return (
    <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <h2 className="text-3xl mb-12 text-center">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="inline-flex items-center gap-2 hover:opacity-70 transition-opacity"
        >
          New Arrivals
          <ChevronDown className={`h-6 w-6 transition-transform ${isExpanded ? 'rotate-180' : ''}`} />
        </button>
      </h2>

      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
        {displayedProducts.map((product) => (
          <button
            key={product.id}
            onClick={() => handleProductClick(product.id)}
            className="relative w-full bg-gray-100 rounded-lg overflow-hidden group"
          >
            {/* 4:3 aspect ratio container */}
            <div className="relative pb-[133.33%]">
              <OptimizedImage
                src={product.photos[0]}
                alt={product.title}
                className="absolute inset-0 w-full h-full object-contain transition-transform duration-300 group-hover:scale-105"
                sizes="(min-width: 1280px) 33vw, (min-width: 768px) 50vw, 100vw"
              />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default NewArrivals;