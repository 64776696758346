import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFeaturedProducts } from '../lib/utils/products/featured';
import type { Product } from '../lib/types/product';
import OptimizedImage from './ui/OptimizedImage';

const FeaturedProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const featuredProducts = await getFeaturedProducts(6);
        setProducts(featuredProducts);
      } catch (error) {
        console.error('Error fetching featured products:', error);
        setError('Failed to load featured products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = (productId: string) => {
    window.scrollTo({ top: 0, behavior: 'instant' });
    navigate(`/product/${productId}`);
  };

  if (error || (!loading && !products.length)) return null;

  if (loading) {
    return (
      <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8 pt-4 pb-16">
        <h2 className="text-3xl mb-12 text-center">Featured Pieces</h2>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          {[...Array(6)].map((_, i) => (
            <div key={i} className="relative pb-[133.33%] bg-gray-100 rounded-lg animate-pulse" />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8 pt-4 pb-16">
      <h2 className="text-3xl mb-12 text-center">Featured Pieces</h2>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
        {products.map((product) => (
          <button
            key={product.id}
            onClick={() => handleProductClick(product.id)}
            className="relative w-full bg-gray-100 rounded-lg overflow-hidden group"
          >
            {/* 4:3 aspect ratio container */}
            <div className="relative pb-[133.33%]">
              <OptimizedImage
                src={product.photos[0]}
                alt={product.title}
                className="absolute inset-0 w-full h-full object-contain transition-transform duration-300 group-hover:scale-105"
                sizes="(min-width: 1280px) 33vw, (min-width: 768px) 50vw, 100vw"
              />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default FeaturedProducts;