import React from 'react';
import { useCartStore } from '../../lib/store/cart';
import CartHeader from './CartHeader';
import CartItems from './CartItems';
import CartFooter from './CartFooter';
import EmptyCart from './EmptyCart';
import CartOverlay from './CartOverlay';

interface CartProps {
  isOpen: boolean;
  onClose: () => void;
}

const Cart: React.FC<CartProps> = ({ isOpen, onClose }) => {
  const { items, total } = useCartStore();

  if (!isOpen) return null;

  return (
    <CartOverlay onClose={onClose}>
      <div className="h-full flex flex-col bg-white shadow-xl">
        <CartHeader onClose={onClose} />
        
        <div className="flex-1 overflow-y-auto">
          {items.length === 0 ? (
            <EmptyCart />
          ) : (
            <div className="px-4 sm:px-6 py-4">
              <CartItems items={items} />
            </div>
          )}
        </div>

        {items.length > 0 && (
          <CartFooter onClose={onClose} />
        )}
      </div>
    </CartOverlay>
  );
};

export default Cart;